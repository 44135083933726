<template>
  <div class="flex flex-col pb-8 lg:flex-row lg:flex-wrap w-full my-2">
    <div class="w-full flex items-center mt-4">
      <p class="title">Fechamentos</p>
      <br />
    </div>
   <chave-pix></chave-pix>

    <fechamentos />


  </div>
</template>

<script>
import Fechamentos from "@/components/Partner/Fechamentos/Fechamentos";
import ChavePix from "@/components/Partner/ChavePix/ChavePix";

export default {
  name: "fechamentos-view",
  components: { Fechamentos, ChavePix },

};
</script>

<style lang="sass" scoped>

.title
  @apply font-bold text-xl text-secondary
  @screen lg
    @apply text-2xl
</style>
