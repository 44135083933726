<template>
  <div class="main-content">
  <transition name="fade">
      <send-nf-modal v-if="fechamentoModal" :fechamento="fechamentoModal" @refresh="getFechamentos()" @closeModal="fechamentoModal = null"></send-nf-modal>
  </transition>
    <div class="overflow-x-auto lg:overflow-x-hidden">
      <vuetable ref="vuetable"
        :fields="header"
        :api-mode="false"
        :css="{tableClass: 'proposals'}"
        :no-data-template="loading ? '' : 'Nenhum fechamento encontrado. Os dados são processados todo dia 1º do mês.'"
        :data="fechamentos"

      >
            <template v-slot:data="props">
          <div class="flex items-center">
            <p class="text-sm font-bold text-left">{{props.rowData.mes.toString().padStart(2,'0')}}/{{props.rowData.ano}}</p>
          </div>
        </template>
        <template v-slot:dataFechamento="props">
          <div class="flex items-center justify-center">
            <p>{{$moment(props.rowData.dataFechamento).format('DD/MM/YYYY')}}</p>
          </div>
       </template>

         <template v-slot:valorLiberadoPeriodo="props">
          <div class="flex items-center justify-center">
            <p class="whitespace-no-wrap">{{`${formatMoney(props.rowData.valorLiberadoPeriodo)}`}}</p>
          </div>
        </template>
            <template v-slot:valorApurado="props">
          <div class="flex items-center justify-center">
            <p class="whitespace-no-wrap">{{`${formatMoney(props.rowData.valorApurado)}`}}</p>
          </div>
        </template>
            <template v-slot:dataPagamento="props">
          <div class="flex items-center justify-center">
            <p>{{(props.rowData.dataPagamento ? $moment(props.rowData.dataPagamento).format('DD/MM/YYYY') : '-')}}</p>
          </div>
        </template>

        <template v-slot:acoes="props">
          <div class="flex items-center justify-center">
            <a v-if="!props.rowData.dataEnvioNF && props.rowData.valorApurado > 0" href="javascript://" alt="Enviar NF" v-on:click="fechamentoModal = props.rowData" title="Enviar NF" class="text-secondary text-1xl">
             <span class="fa fa-upload va-icon fa fa-cloud-upload" alt="Enviar NF" title="Enviar NF"></span>
             Enviar NF
            </a>

            <a v-if="props.rowData.dataEnvioNF" href="javascript://" v-on:click="gerarLinkNF(props.rowData.id)" alt="Enviar NF" title="Enviar NF" class="text-secondary text-1xl">
             <span class="fa fa-upload va-icon fa fa-cloud-upload" alt="Ver NF" title="Ver NF"></span>
             Ver NF enviada
            </a>

            <a v-if="props.rowData.dataPagamento" href="javascript://" v-on:click="gerarLinkComprovantePgto(props.rowData.id)" alt="Ver comprovante pgto" title="Ver comprovante pgto" class="text-secondary text-1xl">
             <span class="fa fa-upload va-icon fa fa-cloud-upload" alt="Ver comprovante pgto" title="Ver comprovante pgto"></span>
            Comprovante pgto
            </a>
        </div>
        </template>
      </vuetable>
    </div>
    <div v-if="loading" class="w-full flex flex-col justify-center items-center my-4">
      <span class="fa fa-spinner fa-spin text-2xl text-secondary"></span>
    </div>

  </div>
</template>

<script>
import MoneyFilter from '@/mixins/moneyFilter'
import SendNfModal from '@/components/Partner/Modal/SendNF'

import Dashboard from '@/services/Partner/Dashboard'

export default {
  name: 'partner-proposals-table',
  components: {  SendNfModal},
  mixins: [MoneyFilter],
  data () {
    return {
      loading: true,
      fechamentoModal: null,

      fechamentos: [],
      header: [
        {
          name: '__slot:data',
          title: 'Mês Referência',
          width: '10%'
        },
        {
          name: '__slot:dataFechamento',
          title: 'Data Fechamento',
          width: '15%'
        },
        {
          name: '__slot:dataPagamento',
          title: 'Data Pagamento',
          width: '15%'
        },
        {
          name: '__slot:valorLiberadoPeriodo',
          title: 'Total Liberado p/ Clientes',
          width: '20%'
        },
        {
          name: '__slot:valorApurado',
          title: 'Total Comissão',
          width: '20%'
        },
        {
          name: '__slot:acoes',
          title: 'Ações',
          width: '20%'
        }
      ],
      paginationCss: {
        wrapperClass: 'border rounded-lg flex m-2 text-secondary',
        activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
        disabledClass: 'text-gray-300 cursor-not-allowed',
        pageClass: 'pagination_page_class',
        linkClass: 'pagination_page_class',
        icons: {
          first: 'fas fa-angle-double-left',
          prev: 'fas fa-angle-left',
          next: 'fas fa-angle-right',
          last: 'fas fa-angle-double-right'
        }
      }
    }
  },
  methods: {
    async getFechamentos () {
      this.fechamentos = (await Dashboard.getFechamentos()).data;
      this.loading = false;
    },

    async gerarLinkNF(fechamentoId){
        location.href = (await Dashboard.getVerNotaFiscal(fechamentoId)).data.data
    },
    async gerarLinkComprovantePgto(fechamentoId){
        location.href = (await Dashboard.getVerComprovantePgto(fechamentoId)).data.data
    }
  },
  async mounted () {
    await this.getFechamentos()
  }
}
</script>

<style lang="sass" scoped>
.main-content
  @apply w-full h-full bg-white border shadow rounded-lg

.title
  @apply text-gray-700 text-sm text-center font-bold mb-2 ml-4
  @screen lg
    @apply text-left text-base

</style>

<style lang="sass">
.proposals
  @apply w-full text-sm
  thead tr th
    @apply p-4 text-xs text-gray-600
    background: #ECECEC
  tr
    @apply border-b border-gray-300
    &:nth-child(even)
      @apply bg-gray-100
    &:not(.text-red-600)
      @apply text-green-600
    &:hover
      @apply bg-gray-200
  td
    @apply px-4 py-2 text-center whitespace-normal
    &:first-child
      @apply w-104

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent

</style>
