<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')" />
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal">
          <p class="title">Enviar Nota Fiscal</p>
          <p><span class="font-bold">CNPJ: </span>28.304.222/0001-17</p>
          <p>
            <span class="font-bold">Razão Social: </span>TUTU DIGITAL TECNOLOGIA
            LTDA
          </p>
          <p>
            <span class="font-bold">Endereço: </span>AV PAULISTA, 1374, BELA
            VISTA, SÃO PAULO, SP, 01.310-100
          </p>
          <p><span class="font-bold">Telefone: </span>(11) 99112-1535</p>
          <p>
            <span class="font-bold">Valor: </span
            >{{ formatMoney(fechamento.valorApurado) }}
          </p>
          <br />
          <hr />
          <br />
          <file-upload
            class="btn btn_select"
            accept="application/pdf"
            :multiple="false"
            v-model="files"
            ref="upload"
          >
            <i class="fas fa-search"></i>
            <span v-if="!files.length" class="text-sm">
              Selecionar arquivo</span
            >
            <span v-if="files.length" class="text-sm">
              {{ files[0].name }}</span
            >
          </file-upload>

          <v-button
            :disabled="files.length == 0"
            class="w-full flex justify-center mt-4"
            @click="confirmDialog = true"
            >Enviar Nota Fiscal</v-button
          >
        </div>
      </div>
    </div>
    <confirm-dialog
      v-if="confirmDialog"
      @confirm="submit"
      @closeModal="confirmDialog = false"
      :loading="loading"
    >
      <p class="title">Atenção</p>
      <p class="text text-center">
        Confirma o envio da nota fiscal referente ao mês
        {{ fechamento.mes.toString().padStart(2, "0") }}/{{ fechamento.ano
        }}<br />
        no valor de {{ formatMoney(fechamento.valorApurado) }}?
      </p>
      <br />
      <p class="text text-sm">{{ files[0].name }}</p>
    </confirm-dialog>
  </div>
</template>

<script>
import VButton from "@/components/Buttons/Button";
import ConfirmDialog from "@/components/Modal/ConfirmDialog";
import MoneyFilter from "@/mixins/moneyFilter";
import VInput from '@/components/Inputs/Input'

import Dashboard from "@/services/Partner/Dashboard";
import FileUpload from "vue-upload-component";

export default {
  props: ["fechamento"],
  components: { VButton, ConfirmDialog, FileUpload },
  mixins: [MoneyFilter],
  data() {
    return {
      confirmDialog: false,
      loading: false,
      files: []
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      const data = new FormData();
      data.append("fechamentoId", this.fechamento.id);
      data.append("arquivoNotaFiscal", this.files[0].file)
      try {
        this.submitted = true;
        await Dashboard.postNF(data, this.uploadPercentage);
        this.files = [];
        this.$emit('closeModal');
        this.$emit('refresh');

      } catch (error) {
        this.$store.dispatch("notification", {
          type: "error",
          message: typeof(error.response.data.erros) == 'object' ? error.response.data.erros[Object.keys(error.response.data.erros)[0]]: error.response.data.erros.map(x => x[Object.keys(x)[0]])
        });
      } finally {
        this.submitted = false;
        this.loading = false;
        this.confirmDialog = false;
      }
    },

    uploadPercentage(e) {
      if (e.lengthComputable)
        this.progress = Math.round((e.loaded * 100) / e.total);
    }
  },
  watch: {
    file: function() {
      console.log(this.file);
    }
  }
};
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-full bg-white z-50 flex flex-col justify-center pb-6 pt-8 rounded-lg
  @screen lg
    @apply w-1/2

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.content_modal
  @apply flex flex-col w-full px-4
  @screen lg
    @apply px-8

.title
  @apply font-bold text-lg text-black text-center uppercase text-primary mb-4
  @screen lg
    @apply text-xl
</style>
